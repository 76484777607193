import * as React from 'react'
import Select from 'react-select';

export interface State {
  selectedOption : null,
  options: any 
}

export interface Props {
  handleExperienceChange: any
  experience: any,
  experienceOptions:any
}

class SearchOnExperience extends React.Component<Props, State> {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <Select
        value={this.props.experience}
        onChange={this.props.handleExperienceChange}
        className='react-select-container'
        isClearable
        classNamePrefix="react-select"
        placeholder='Experience'
        options={this.props.experienceOptions}
      />
    )
  }
}

export default SearchOnExperience