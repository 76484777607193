import * as React from "react";
import { Card, Grid, Icon } from "tabler-react";
import { IJob } from "types/job";
import { makeSlug } from "../../utils/Utility";

interface Props {
  job: IJob;
}

class HomeRecentJobsCard extends React.Component<Props> {
  render() {
    return (
      <a
        style={{ textDecoration: "none", color: "#6e7687" }}
        href={
          "/job/" + makeSlug(this.props.job.title) + "-" + this.props.job.id
        }
      >
        <Card>
          <Card.Header>
            <Card.Title>{this.props.job.title}</Card.Title>
          </Card.Header>
          <Card.Body>
            {this.props.job.description.replace(/<[^>]*>/g, "").slice(0, 100)}
            ...
          </Card.Body>
          <Card.Footer>
            <Grid.Row>
              <Grid.Col width={6}>
                <Icon name="briefcase" />{" "}
                {`${this.props.job.experience.min}-${this.props.job.experience.max} Yrs`}
              </Grid.Col>
              {/* <Grid.Col width={6} className="text-right">
                <img src="static/images/rupee.png" alt="Rupee" width="10" />
                {`${this.props.job.salary.min}-${this.props.job.salary.max}LPA`}
              </Grid.Col> */}
            </Grid.Row>
          </Card.Footer>
        </Card>
      </a>
    );
  }
}

export default HomeRecentJobsCard;
