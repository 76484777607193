import * as React from 'react'

import { Grid, Header } from 'tabler-react'

import HomeSearchForm from './HomeSearchForm.react'
import Search from '../Search'

interface Props {
  cities: {}[],
  functionalArea: {}[]
}

class HomeSearchPanel extends React.Component<Props>{
  render () {
    return (
      <HomeSearchForm>
        <Grid.Row className='margin-none'>
          <Grid.Col lg={12} xs={12} className='padding-none'>
            <Header.H3 className='uppercase search-label'>
              Find best jobs from education industry 
            </Header.H3>
          </Grid.Col>
        </Grid.Row>
        <Search appliedFilters={{}} cities={this.props.cities} functionalArea={this.props.functionalArea} />
      </HomeSearchForm>
    )
  }
}

export default HomeSearchPanel
