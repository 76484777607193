import { connect } from "react-redux";
import fetch from "isomorphic-unfetch";

import Layout from "../components/Layout/Layout.react";
import FunctionalAreaTable from "../components/FunctionalAreaTable";
import HomeRecentJobs from "../components/HomeRecentJobs";
import HomeSearchPanel from "../components/HomeSearchPanel";
import { ROOT_URL, JOB_SEATRCH_URL } from "../actions/types";

const Home = ({ functionalArea, recentJobs, cities, functionalAreaSearch }) => (
  <Layout
    title={"No. 1 Job portal for startups and Edtech| All Good Jobs "}
    description={
      "All Good Jobs - Academic Jobs Curriculum Designing Jobs Education Industry Jobs Edtech Jobs Teaching vacancies"
    }
  >
    <React.Fragment>
      <HomeSearchPanel cities={cities} functionalArea={functionalAreaSearch} />
      <HomeRecentJobs recentJobs={recentJobs} />
      <FunctionalAreaTable functionalArea={functionalArea} />
    </React.Fragment>
  </Layout>
);

Home.getInitialProps = async () => {
  let functionalAreaSearch = await fetch(`${ROOT_URL}/api/functional-area`);
  functionalAreaSearch = await functionalAreaSearch.json();
  let functionalArea = await fetch(`${ROOT_URL}/api/functional-area-filter`);
  functionalArea = await functionalArea.json();
  let recentJobs = await fetch(`${JOB_SEATRCH_URL}/indexes/jobs/search`, {
    method: "POST",
    body: JSON.stringify({
      limit: 15,
      sort: ["createdAt:desc"],
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer d7723c2a8b93c98d4f8dd318a936b394d24adc876fe60b7f4853bb2e82c9",
    },
  });
  recentJobs = await recentJobs.json();
  let cities = await fetch(`${ROOT_URL}/api/cities`);
  cities = await cities.json();
  return {
    functionalAreaSearch: functionalAreaSearch.success
      ? functionalAreaSearch.functionalArea
      : [],
    functionalArea: functionalArea.success ? functionalArea.functionalArea : [],
    recentJobs: recentJobs.hits ? recentJobs.hits : [],
    cities: cities.success ? cities.cities : [],
  };
};

export default Home;
