import * as React from 'react'
import Select from 'react-select';

export interface State {
  selectedOption: null,
  options: any
}

export interface Props {
  handleSalaryChange: any
  salary:any
  salaryOptions:any
}

class SearchOnSalary extends React.Component<Props, State> {
  constructor(props){
    super(props)
  }

  render () {
    return(
      <Select
        value={this.props.salary}
        onChange={this.props.handleSalaryChange }
        placeholder="Salary"
        isClearable
        isSearchable
        className='react-select-container'
        classNamePrefix="react-select"
        options={this.props.salaryOptions}
      />
    )
  }
}

export default SearchOnSalary