import * as React from 'react'

import Creatable from 'react-select/lib/Creatable'

export interface Props {
  cities:any
  locations: []
  handleLocationChange: any
}

export interface State {
  selectedOption: null
}

class SearchOnLocation extends React.Component<Props, State> {
  constructor(props){
    super(props)

    this.state = {
      selectedOption: null
    }
  }

  formatCreateLabel = inputValue => (
    <span>{inputValue}</span>
  )

  render () {
    return (
      <Creatable 
        value={this.props.locations}
        onChange={this.props.handleLocationChange}
        placeholder='Locations'
        formatCreateLabel={this.formatCreateLabel}
        isMulti
        createOptionPosition={'first'}
        className='react-select-container'
        classNamePrefix="react-select"
        options={this.props.cities.map(obj => {
            return {
              value: obj.name,
              label: obj.name
            }
        })
        } 
      />
    )
  }
}

export default SearchOnLocation
