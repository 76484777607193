import * as React from 'react'

import dynamic from 'next/dynamic'

const FunctionalAreaTableCard = dynamic(import('./FunctionalAreaTableCard.react'))

interface Props {
  functionalArea: any
}

class FunctionalAreaTable extends React.Component<Props> {

  render () {
    return (
      <FunctionalAreaTableCard functionalArea={this.props.functionalArea} />
    )
  }
}

export default FunctionalAreaTable
