import * as React from "react";

import Creatable from "react-select/lib/Creatable";

export interface Props {
  value: string;
  handleQueryChange: Function;
}

export interface State {
  selectedOption: null;
}

class SearchOnQuery extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
    };
  }

  formatCreateLabel = (inputValue) => <span>{inputValue}</span>;

  render() {
    const value = this.props.value
      ? [{ label: this.props.value, value: this.props.value }]
      : [];
    return (
      <Creatable
        value={value}
        onChange={this.props.handleQueryChange}
        formatCreateLabel={this.formatCreateLabel}
        placeholder="Keyword / Job title "
        isMulti={false}
        className="react-select-container"
        classNamePrefix="react-select"
      />
    );
  }
}

export default SearchOnQuery;
