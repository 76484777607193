import * as React from "react";

import { Grid } from "tabler-react";

import HomeRecentJobsCard from "./HomeRecentJobsCard.react";
import { IJob } from "types/job";

interface Props {
  recentJobs: Array<IJob>;
}

class HomeRecentJobs extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    const recentJobs = this.props.recentJobs;
    return (
      <div className="container">
        <Grid.Row className="margin-none">
          <h3 className="uppercase"> Recent Jobs </h3>
        </Grid.Row>
        <Grid.Row>
          {recentJobs.map((job, index) => {
            return (
              <Grid.Col key={index} lg={4}>
                <HomeRecentJobsCard key={index} job={job} />
              </Grid.Col>
            );
          })}
        </Grid.Row>
      </div>
    );
  }
}

export default HomeRecentJobs;
