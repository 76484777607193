import * as React from 'react'

import { Grid } from 'tabler-react'

class HomeSearchForm extends React.Component {
  render () {
    return (
      <Grid.Row className='home-search-wrapper-margin'>
        <Grid.Col className='HomeSearchWrapper'>
          <div className='container'>
            {this.props.children}
          </div>
        </Grid.Col>
      </Grid.Row> 
    )
  }
}

export default HomeSearchForm
