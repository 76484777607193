import * as React from "react";

import { Grid, Card, Button } from "tabler-react";

import SearchOnLocation from "./SearchOnLocation.react";
import SearchOnSalary from "./SearchOnSalary.react";
import SearchOnExperience from "./SearchOnExperience.react";
import SearchOnQuery from "./SearchOnQuery.react";

export interface State {
  experience: any;
  salary: {};
  locations: [];
  keywords: [];
  query: string;
  keywordQuery: string;
  salaryQuery: string;
  locationQuery: string;
  experienceQuery: string;
  searchUrl: string;
  filterObj: any;
  salaryOptions: any;
  experienceOptions: any;
  functionalArea: any;
}

interface Props {
  cities: {}[];
  functionalArea: {}[];
  appliedFilters: any;
}

class Search extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.handleExperienceChange = this.handleExperienceChange.bind(this);
    this.handleSalaryChange = this.handleSalaryChange.bind(this);
    this.handleFunctionalAreaChange =
      this.handleFunctionalAreaChange.bind(this);
    this.onCreateOption = this.onCreateOption.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);

    // Make salary options
    let salary = [];
    for (let index = 0; index < 30; index++) {
      const element = {};
      element["value"] = index;
      element["label"] = index + " LPA";
      salary.push(element);
    }

    // Make experience options
    let experience = [];

    for (let index = 0; index < 30; index++) {
      const element = {};
      element["value"] = index;
      element["label"] = index + " Year";
      experience.push(element);
    }

    this.state = {
      experience: {},
      salary: {},
      locations: [],
      keywords: [],
      query: "",
      salaryOptions: salary,
      experienceOptions: experience,
      keywordQuery: "",
      salaryQuery: "",
      locationQuery: "",
      experienceQuery: "",
      filterObj: {},
      searchUrl: "/jobs?search=true",
      functionalArea: [],
    };
  }

  componentWillMount() {
    let keywords = this.props.appliedFilters.keywords
      ? this.props.appliedFilters.keywords.map((element) => {
          return element;
        })
      : [];
    let query = this.props.appliedFilters.query || "";
    let locations = this.props.appliedFilters.locality
      ? this.props.appliedFilters.locality.map((element) => {
          return element;
        })
      : [];
    let salary =
      this.props.appliedFilters && this.props.appliedFilters.salary
        ? this.props.appliedFilters.salary
        : "";
    let experience =
      this.props.appliedFilters && this.props.appliedFilters.exp
        ? this.props.appliedFilters.exp
        : "";
    this.setState({
      keywords: this.props.appliedFilters.keywords
        ? this.props.appliedFilters.keywords.map((element) => {
            let obj = {};
            obj["label"] = element;
            obj["value"] = element;
            return obj;
          })
        : [],
      query,
      locations: this.props.appliedFilters.locality
        ? this.props.appliedFilters.locality.map((element) => {
            let obj = {};
            obj["label"] = element;
            obj["value"] = element;
            return obj;
          })
        : [],
      salary:
        this.props.appliedFilters && this.props.appliedFilters.salary
          ? {
              value: this.props.appliedFilters.salary,
              label: this.props.appliedFilters.salary + " LPA",
            }
          : "",
      experience:
        this.props.appliedFilters && this.props.appliedFilters.exp
          ? {
              value: this.props.appliedFilters.exp,
              label: this.props.appliedFilters.exp + " Year",
            }
          : "",
      keywordQuery: keywords.length ? "&&keywords=" + keywords.toString() : "",
      salaryQuery: salary ? "&&salary=" + salary : "",
      locationQuery: locations.length
        ? "&&locality=" + locations.toString()
        : "",
      experienceQuery: experience ? "&&exp=" + experience : "",
      functionalArea: this.props.functionalArea,
    });
  }

  onCreateOption(inputValue) {
    this.setState({
      functionalArea: [...this.state.functionalArea, ...[{ name: inputValue }]],
    });
  }

  handleFunctionalAreaChange(selectedOption) {
    if (selectedOption.length) {
      let keywords = [];
      selectedOption.forEach((element) => {
        keywords.push(element.label);
      });
      this.setState({
        keywords: selectedOption,
        keywordQuery: "&&keywords=" + keywords.toString(),
        filterObj: {
          keywords: keywords,
        },
      });
    } else {
      this.setState({
        keywords: selectedOption,
        keywordQuery: "",
        filterObj: {
          keywords: "",
        },
      });
    }
  }

  handleQueryChange(selected) {
    if (selected.value) {
      this.setState({
        query: selected.value,
        keywordQuery: "&&query=" + selected.value,
        filterObj: {
          query: selected.value,
        },
      });
    } else {
      this.setState({
        query: "",
        keywordQuery: "",
        filterObj: {
          query: selected.value,
        },
      });
    }
  }

  handleLocationChange(selectedOption) {
    this.setState({ locations: selectedOption });
    if (selectedOption.length) {
      let locations = [];
      selectedOption.forEach((element) => {
        locations.push(element.label);
      });
      this.setState({
        locations: selectedOption,
        locationQuery: "&&locality=" + locations.toString(),
        filterObj: {
          locations: locations,
        },
      });
    } else {
      this.setState({
        locations: selectedOption,
        locationQuery: "",
        filterObj: {
          locations: "",
        },
      });
    }
  }

  handleExperienceChange(selectedOption) {
    this.setState({
      experience: selectedOption,
      experienceQuery: selectedOption ? "&&exp=" + selectedOption.value : "",
      filterObj: {
        experience: selectedOption ? selectedOption.value : null,
      },
    });
  }

  handleSalaryChange(selectedOption) {
    this.setState({
      salary: selectedOption,
      salaryQuery: selectedOption ? "&&salary=" + selectedOption.value : "",
      filterObj: {
        salary: selectedOption ? selectedOption.value : null,
      },
    });
  }

  render() {
    return (
      <Card className="search-card">
        <Grid.Row cards className="margin-none search-wrapper-row">
          <Grid.Col lg={5} xs={12} className="search-input-padding">
            <SearchOnQuery
              handleQueryChange={this.handleQueryChange}
              value={this.state.query}
            />
          </Grid.Col>
          <Grid.Col lg={3} xs={12} className="search-input-padding">
            <SearchOnLocation
              handleLocationChange={this.handleLocationChange}
              locations={this.state.locations}
              cities={this.props.cities}
            />
          </Grid.Col>
          <Grid.Col lg={1.5} xs={12} className="search-input-padding">
            <SearchOnSalary
              handleSalaryChange={this.handleSalaryChange}
              salary={this.state.salary}
              salaryOptions={this.state.salaryOptions}
            />
          </Grid.Col>
          <Grid.Col lg={1.5} xs={12} className="search-input-padding">
            <SearchOnExperience
              handleExperienceChange={this.handleExperienceChange}
              experience={this.state.experience}
              experienceOptions={this.state.experienceOptions}
            />
          </Grid.Col>
          <Grid.Col lg={1} xs={12} className="search-input-padding">
            <Button
              type="button"
              color="blue"
              icon="search"
              RootComponent="a"
              href={
                this.state.searchUrl +
                this.state.keywordQuery +
                this.state.locationQuery +
                this.state.experienceQuery +
                this.state.salaryQuery
              }
            ></Button>
          </Grid.Col>
        </Grid.Row>
      </Card>
    );
  }
}

export default Search;
